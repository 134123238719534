<template>
  <card :title="$t('speakers.info')">
    <form @submit.prevent="create" @keydown="form.onKeydown($event)">
      <alert-success :form="form" :message="$t('speakers.updated')" />

      <speaker-form :form="form"></speaker-form>

      <!-- Submit Button -->
      <div class="form-group row">
        <div class="col-md-9 ml-md-auto">
          <v-button :loading="form.busy" type="success">{{ $t('update') }}</v-button>
        </div>
      </div>
    </form>
  </card>
</template>

<script>
import Form from "vform"
import SpeakerForm from "../../accounts/speakers/form"
import { objectToFormData } from 'object-to-formdata'

export default {
  scrollToTop: false,
  components: {
    "speaker-form": SpeakerForm
  },
  data: () => ({
    form: new Form({
      church_id: null,
      name: "",
      thumbnailUrl: "",
      thumbnail: null,
    })
  }),
  created() {
    const church_id = this.$route.query.church_id;
    this.form['church_id'] = church_id;
  },
  methods: {
    async create() {
      const { data } = await this.form.submit('post','/api/v1/admin/speakers', {
        // Transform form data to FormData
        transformRequest: [function(data) {
          return objectToFormData(data);
        }]
      });
      
      const speaker = data.speaker;

      // Update the store
      this.$store.dispatch("admin/updateSpeakers", { speaker: speaker });

      // Update the form
      this.$router.push({name: 'admin.speakers.edit', params: {id: speaker.id}, query: {church_id: speaker.church_id}})
    }
  }
};
</script>
